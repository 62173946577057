@media (max-width: 768px) {
    .table-card {
        width: unset;
        table-layout: fixed;
    }

    .table-card-container.scrollable {
        overflow-x: scroll;
        min-height: 22.5rem;
    }

    .table-card tbody tr td:first-child {
        background-color: #006293;
        color: white;
        position: sticky;
        left: 0;
        z-index: 1;
    }

    .table-card td {
        padding: 0 1.25rem;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    table tr {
        height: 1.625rem;
    }

    .dark-blue {
        color: #006293;
    }

    .dots-container {
        position: sticky;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
    }
}
