.table-wrapper {
    overflow-x: scroll;
}

table {
    border-collapse: collapse;
    width: 100%;
}

table tbody {
    border: 1px solid #d9d9d9;
}

table tbody td {
    border: 1px solid #d9d9d9;
    border-left: 0;
    border-right: 0;
    padding: 8px;
    height: 2rem;
    padding: 1.125rem;
}

table thead {
    background-color: #006293;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
}

table thead tr th {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 1.125rem;
    white-space: nowrap;
}

table tr {
    text-align: left;
    height: 3rem;
}

table tr:nth-child(even) {
    background-color: #fbfbfb;
}

table tbody tr td {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.selected {
    background-color: #f4fafd !important;
    border-left: 2px solid #007ab8;
}

.selected-main {
    background-color: #ebf4f9 !important;
    border-left: 2px solid #007ab8;
}

table tbody tr:hover {
    background-color: #dceef7;
    border-left: 2px solid #006293;
}

table thead tr th:first-child {
    border-top-left-radius: 8px;
}

table thead tr th:last-child {
    border-top-right-radius: 8px;
}

.clickable {
    cursor: pointer;
}

.dark-blue {
    color: #006293;
}

.underline {
    text-decoration: underline;
    color: #006293;
}

.collapse-btn {
    display: flex;
    width: 1rem;
    height: 1rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: var(--k-dark-blue-aa, #006293);
}

.clickable.underline.comment {
    max-width: 7rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
}

/* mobile */
@media (max-width: 768px) {
    table thead tr th {
        white-space: unset;
    }

    table thead tr th,
    table tbody td {
        padding: 1rem 0.5rem;
    }

    table tbody tr:hover {
        background-color: white;
        border-left: none;
    }

    table tbody tr:nth-child(even):hover {
        background-color: #fbfbfb;
    }
}
