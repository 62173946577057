.search-page-layout-container {
    background: linear-gradient(
        107deg,
        #f6faff -10.3%,
        #f8fdff 49.18%,
        #cce4f1 168.94%
    );
    padding-top: 5vh;
    height: 90vh;
    overflow-y: scroll;
}

.search-retail-header {
    margin-left: 3.75rem;
    color: #031611;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 200% */
}

.search-container {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    margin-top: 5vh;
    padding: 5vh 10vw 10vh 10vw;
}

.search-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.search-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 3vh;
}

.search-input-group {
    display: flex;
    margin-top: 2vh;
    align-items: center;
}

.search-inputs-container {
    display: flex;
    flex: 0 0 65%;
    position: relative;
}

.search-input-area {
    margin-right: 1vw;
    padding-left: 16px;
    height: 42px;
}

.clear-btn {
    display: flex;
    height: 46px;
    padding: 0 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: black;
    margin-right: 1vw;
}

.clear-btn:hover {
    background-color: #007AB8;
}

.search-btn {
    display: flex;
    height: 48px;
    padding: 20px 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border: 0;
    border-radius: 100px;
    background-color: #007AB8;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}

.search-btn:hover {
    background-color: #000;
}

.result-container {
    margin-top: 3rem;
}

.result-title {
    margin-bottom: 1vh;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
}

.btn-group {
    display: flex;
    width: 100%;
}

.search-error-message {
    color: #B3261E;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;  
    text-align: left;
    margin-top: 0.25rem;
    position: absolute;
    left: 0;
    bottom: -50%;
}

/* mobile */
@media (max-width: 768px) {
    .search-retail-header {
        margin-left: 1.25rem;
    }

    .search-page-layout-container {
        display: flex;
        flex-direction: column;
    }

    .search-container {
        margin-left: 0;
        margin-right: 0;
        padding: 1.25rem;
        flex-grow: 1;
    }

    .search-input-group {
        flex-direction: column;
        gap: 1.5rem;
    }

    .search-inputs-container {
        width: 100%;
        flex-direction: column;
        gap: 1.25rem;
    }

    .search-error-message { 
        position: relative;
    }
}