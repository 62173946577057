.order-page-header-container {
    margin-left: 2.5rem;
}

.retail-header {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 200% */
    letter-spacing: -1px;
}

.back-link {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    width: max-content;
    margin-top: 1.5rem;
}
@media (max-width: 768px) {
    .order-page-header-container {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        width: unset;
    }
}
