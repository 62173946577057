.order-detail-summary-box {
    display: flex;
    white-space: nowrap;
    justify-content: space-evenly;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    padding: 1rem;
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
    height: 6.25rem;
    align-items: center;
    width: 50%;
}

.order-detail-summary-box-item-label {
    color: #2c2a28;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0px;
}

.order-detail-summary-box-item-value {
    color: #007ab8;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;
    margin-top: 1rem;
}

@media (max-width: 768px) { 
    .order-detail-summary-box {
        flex: 0 0 100%;
        flex-shrink: unset;
        margin-top: 0;
        height: max-content;
    }

    .order-detail-summary-box {
        flex-direction: column;
        gap: 0.25rem;
        align-items: baseline;
    }

    .order-detail-summary-box-item {
        display: flex;
        align-items: center;
    }

    .order-detail-summary-box-item-label {
        width: 8rem;
    }

    .order-detail-summary-box-item-value {
        margin-top: 0;
        font-size: 16px;
    }
}