.order-detail-container {
    display: flex;
    flex-direction: column;
}

.order-detail-page-title {
    color: black;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-top: 1.5rem;
}

.order-detail-header-row {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    /* justify-content: space-between; */
}

.order-detail-content-container {
    margin-top: 1.875rem;
}

.sort-group {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
}

.sort-title {
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.sort-btn-group {
    margin-top: 1rem;
    display: flex;
    min-width: 40rem;
    gap: 1rem;
    width: 40%;
}
.card-group {
    display: flex;
    width: calc(51% - 3.75rem);
    gap: 1.25rem;
}

@media (max-width: 768px) {
    .order-detail-header-row {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        justify-content: space-around;
        margin-top: 1.125rem;
    }

    .sort-btn-group {
        flex-direction: column;
        min-width: unset;
        width: 100%;
        margin-top: 1.125rem;
        gap: 1.125rem;
    }

    .card-group {
        flex: 0 0 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.125rem;
        margin-bottom: 1.125rem;
    }
} 