.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Shadow effect */
    z-index: 1;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007ab8;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 1s linear infinite;
    z-index: 1000;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}