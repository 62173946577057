.container {
    width: 100%;
    height: 95vh;
    background-color: #007AB8;
    overflow: hidden;
}

.login-card-container {
    width: max-content;
    max-width: 300px;
    height: max-content;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-top: 20vh;
}

.card-title {
    font-weight: 700;
    font-size: 24px;
    margin-top: 3vh;
}

.card-helper {
    font-size: 14px;
    margin-top: 1vh;
}

.input-group {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    position: relative;
}

.input-area {
    min-width: 100px;
    width: calc(100% - 24px);
    font-size: 16px;
}

.login-btn {
    margin-top: 2vh;
    margin-bottom: 3vh;
    border-radius: 100px;
    background: #007AB8;
    width: 100%;
    min-width: 300px;
    height: 48px;
    padding: 1rem 3rem;
    border: 0px;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}

.login-btn:hover {
    background-color: #000;
}

.error-message {
    color: #B3261E;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;  
    text-align: left;
    margin-top: 0.25rem;
}

.icon-follow {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}


@media (max-width: 768px) {
    .login-card-container {
        width: calc(100% - 10vw);
        margin: 0;
        max-width: unset;
        height: 100%;
        border-radius: 0;
        padding-top: 4rem;
    }
}