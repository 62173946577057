.page-container {
    padding-top: 1.5rem;
}

.page-content-container {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
}

@media (max-width: 768px) { 
    .page-container {
        padding-top: 3rem;
    }

    .page-content-container {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        width: unset;
    }
}