/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Shadow effect */
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    position: relative;
    width: 40%;
    height: max-content;
    background-color: white;
    z-index: 1000;
    border-radius: 20px;
    min-height: 14.25rem;
    min-width: 22.875rem;
    padding: 2.5rem;
    overflow-y: auto;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
}

.no-scroll {
    overflow: hidden;
}

.close-btn {
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    background-color: white;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
}

@media (max-width: 768px) {
    .modal-content {
        width: 100vw;
        height: 100vh;
        min-width: unset;
        min-height: unset;
    }

    .close-btn {
      top: 2.75rem;
      right: 0.5rem;
  }
}
