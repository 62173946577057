.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Shadow effect */
    z-index: 999; /* Behind the side panel */
}

.side-panel {
    position: fixed;
    top: 0;
    right: min(-100%, -40.5rem);
    width: 40%;
    height: 100%;
    background-color: white;
    transition: right 0.3s ease;
    z-index: 1000;

    display: flex;
    padding: 2rem 1.5rem 0 1.5rem;
    flex-direction: column;
    gap: 1.5rem;
    flex-shrink: 0;
    min-width: 37.5rem;
}

.side-panel.open {
    right: 0;
}

.close-btn {
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    background-color: white;
    position: absolute;
    top: 2.5rem;
    right: 0.5rem;
}

.content-wrapper {
    overflow-y: auto;
    height: 75vh;
}

.no-scroll {
    overflow: hidden;
}

@media (max-width: 768px) {
    .side-panel {
        min-width: unset;
        width: calc(100% - 3rem);
    }
}
