.bo-container {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 3.125rem;
}

.bo-label {
    color: #000406;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.bo-img {
    margin-left: 0.25rem;
}

.bo-comment-tooltip {
    visibility: hidden;
    display: flex;
    width: 12.5rem;
    min-height: 1.5rem;
    padding: 0.25rem 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    background: #322F35;

    color: #F5EFF7;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.4px;

    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.bo-container:hover .bo-comment-tooltip {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 768px) {
    .bo-label {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
    }
}