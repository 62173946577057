.sort-btn {
    display: flex;
    height: 3.5rem;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background: white;
    align-items: center;
    justify-content: space-between;
    min-width: 15rem;
}

.sort-label {
    color: #000406;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}

.clicked {
    background-color: #007AB8;
    border-color: #007AB8;
    color: white;
}

.label-clicked {
    color: white;
}