.header-container {
    width: calc(100% - 2.5rem);
    height: 2.875rem;
    background-color: rgb(40, 40, 40);
    min-height: 2.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2.5rem;
}

.content-group {
    color: white;
    display: flex;
    margin-right: 2vw;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
}

.logo-img {
    width: auto;
    height: 1.5rem;
    object-fit: contain;
}

.logout_btn {
    cursor: pointer;
}

@media (max-width: 768px) {
    .header-container {
        width: calc(100% - 1.5rem);
        padding-left: 1.5rem;
    }
}