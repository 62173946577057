.summary-card-container{
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #ebf4f9;
    display: flex;
    gap: 0.5rem;
    width: 100%;
    padding: 1rem 1.25rem;
    align-items: center;
    align-self: stretch;
    white-space: nowrap;
    height: 6.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.card-content-container {
    margin-left: 0.25rem;
}

.card-content-label,
.card-content-comment {
    color: #006293;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.card-content-value {
    color: #006293;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.card-content-comment {
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
}

.order-detail-subtitle {
    color:#031611;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
}

.order-detail-sort-group {
    color:#031611;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

@media (max-width: 768px) { 
    .summary-card-container {
        margin: 0;
        width: unset;
        height: 1.875rem;
    }

    .summary-card-container.full {
        grid-column: 1/3;
        justify-content: center;
    }
}