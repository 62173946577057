.pill-container {
    display: flex;
    width: 100px;
    height: 28px;
    padding: 0.25rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 999px;
}

.shipped {
    border: 1px solid #006293;
    background: #f4fafd;
}

.finished {
    align-self: stretch;
    border: 1px solid #00763d;
    background: #e1ffe6;
}

.production {
    border: 1px solid #6e6e6e;
    background: #fff;
}

.ordered {
    flex-shrink: 0;
    border: 1px solid #007ab8;
    background: #fff;
}

.pill-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
    margin-right: 0.25rem;
}

.pill-icon {
    width: 0.875rem;
    height: 0.875rem;
}

.shipped .pill-label {
    color: #006293;
}

.finished .pill-label {
    color: #00763d;
}

.production .pill-label {
    color: #6e6e6e;
}

.ordered .pill-label {
    color: #007AB8;
}

.collapse .pill-label {
    cursor: pointer;
}

.pill-icon-container {
    display: flex;
    align-items: center;
}

.collapse .pill-icon-container {
    display: flex;
    align-items: center;
    background: #006293;
    border-radius: 100px;
    scale: 0.75;
    padding: 0.3rem 0.29rem 0.22rem 0.29rem;
}

.collapse .pill-icon {
    background-color: #006293;
    border-radius: 100px;
    padding: 0.25rem;
}


@media (max-width: 768px) {
    .pill-container {
        height: 0.75rem;
        width: 4.25rem;
    }

    .collapse.pill-container {
        padding-left: 0;
        justify-content: left;
    }

    .collapse .pill-label {
        margin-right: 0;
    }
}