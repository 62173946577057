.footer-container {
    margin-top: 3rem;
    background: #2c2a28;
    height: auto;
    padding: 2.5rem 7.5rem 3.75rem 7.5rem;
}

.footer-title {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
    align-self: stretch;
    margin: 0;
}

.footer-content-container {
    display: flex;
    align-items: center;
    gap: 4rem;
}

.footer-content {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
}

.footer-content a {
    color: #fff;
}

.footer-contact-btn {
    display: flex;
    width: 17.5rem;
    height: 3rem;
    padding: 1.25rem 4rem;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #007ab8;
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    border: none;
}

.footer-contact-btn:hover {
    background-color: #000;
}

@media (max-width: 768px) {
    .footer-container {
        padding: 2.5rem 2.5rem 3.75rem;
    }

    .footer-content-container {
        flex-direction: column;
        gap: 0;
    }

    .footer-content {
        margin: 1.5rem 0;
    }
}
